import React from "react";
import contact from "../assets/contact.jpg";

const Contact = () => {
  return (
    <div className="text-center m-4 p-4 text-lg">
      <h1 className="font-bold text-2xl uppercase my-2">
        Contact Us
      </h1>
      <img className='w-1/2 h-auto mx-auto mt-8' src={contact} alt="contact" />
    </div>
  );
};

export default Contact;
