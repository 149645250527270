import React from "react";
import Product from "./Product.js";
import { Link } from "react-router-dom";

const ProductList = ({ products }) => {
  return (
    <div className="flex flex-wrap justify-center m-2 p-2">
      {products.map((product) => (
        <Link key={product.productId} to={"/product/" + product.productId}>
          <Product data={product} />
        </Link>
      ))}
    </div>
  );
};

export default ProductList;
