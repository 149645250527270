import React from "react";

const Product = ({ data }) => {
  const { productName, price, imageURL, unit } = data;
  return (
    <div className="w-96 border border-gray-200 bg-gray-100 m-8 p-2 rounded-xl shadow-lg cursor-pointer">
      <img className="rounded-lg" src={imageURL} alt="image" />
      <h1 className="font-medium my-2 mx-2">{productName}</h1>
      <div className="flex justify-between">
        <p className="font-bold mx-2">₹{price}</p>
        <p className="font-bold mx-2">{unit}</p>
      </div>
    </div>
  );
};

export default Product;
