import React, { useEffect, useState } from 'react';
import ProductList from './ProductList.js';
import {BASE_URL} from '../utils/constants.js';
import banner from '../assets/banner.jpg';

const Body = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = async() => {
    const data = await fetch(BASE_URL + "/getAllProducts", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'orgId': 'e1',
        'userName': 'Ganesh'
      }
    });

    const json = await data.json();
    setProducts(json)
  }

  return (
    <div className='m-4 p-4'>
      <img className='w-1/2 h-auto mx-auto mb-8' src={banner} alt="banner" />
      <h1 className='text-2xl font-bold text-center uppercase my-4'>Our Products</h1>
      <ProductList products={products} />
    </div>
  )
}

export default Body;