import React from 'react';
import about from '../assets/about.jpg';

const About = () => {
  return (
    <div className="text-center m-4 p-4 text-lg">
      <h1 className="font-bold text-2xl uppercase my-2">
        About Us
      </h1>
      <img className='w-1/2 h-auto mx-auto mt-8' src={about} alt="about" />
    </div>
  )
}

export default About;