import React, { useEffect, useState } from "react";
import { BASE_URL } from "./constants.js";

const useProductDetail = (productId) => {
  const [productInfo, setProductInfo] = useState(null);

  useEffect(() => {
    getProductDetail();
  }, []);

  const getProductDetail = async () => {
    const data = await fetch(BASE_URL + "/getProductBasedOnId", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "orgId": "e1",
        "userName": "Ganesh",
        "productId": productId
      },
    });

    const json = await data.json();
    setProductInfo(json)
  };

  return productInfo;
};

export default useProductDetail;
