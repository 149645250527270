import React from "react";
import { useParams } from "react-router-dom";
import useProductDetail from "../utils/useProductDetail.js";

const ProductDetail = () => {
  const { productId } = useParams();
  const productInfo = useProductDetail(productId);

  if (productInfo === null) {
    return <p>Loading</p>;
  }
  
  const { productName, productDescription, unit, price, imageURL } = productInfo;

  return (
    <div className="border flex gap-4 border-gray-200 bg-gray-100 m-24 p-2 rounded-xl shadow-lg w-1/2 h-96 items-center mx-auto">
      <div className="w-1/2">
        <img className="rounded-lg" src={imageURL} alt="image" />
      </div>
      <div className="w-1/2">
        <h1 className="font-bold m-2 text-3xl">{productName}</h1>
        <p className="mx-2 my-8">{productDescription}</p>
        <div className="flex justify-between">
          <p className="font-bold m-2 p-2 text-2xl border border-gray-200 bg-gray-200">
            ₹{price}
          </p>
          <p className="font-bold m-2 p-2 text-2xl border border-gray-200 bg-gray-200">
            {unit}
          </p>
          {/*<button className="font-bold m-2 p-2 text-2xl border border-gray-200 bg-gray-200">Add To Cart</button>*/}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
